import { useEffect, useState, useRef } from 'react';
import { useRouter } from 'next/router';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import * as Sentry from "@sentry/nextjs";
import axios from 'axios';
import * as Yup from 'yup';
import getTrackingParams from '../../../utils/getTrackingParams';
import { legacyParseFirstJsonInResponse } from '../../../utils/common/legacyCompatibility';

const validationSchema = Yup.object({
  'call-taken-by': Yup.string(),
  name: Yup.string().required('Required'),
  'job-title': Yup.string(),
  email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.number(),
  'years-employed': Yup.string().required('Required'),
  'still-employed': Yup.string().required('Required'),
  salary: Yup.string().required('Required'),
  'settlement-agreement': Yup.string(),
  'what-is-your-objective': Yup.string(),
  description: Yup.string()
    .min(200, 'Description must be at least 200 characters long')
    .required('Required'),
  terms: Yup.array().min(1, 'Required').required('Required'),
});

const CDF = ({ classNames = '', sideBar, contactFieldLayout = false }) => {
  const router = useRouter();
  const recognitionRef = useRef(null);
  const isUserLoggedIn = router.query.loggedIn === 'true';
  const [showToolTipYe, setShowToolTipYe] = useState(false);
  const [showToolTipAi, setShowToolTipAi] = useState(false);
  const [showToolTipSa, setShowToolTipSa] = useState(false);
  const [showToolTipDes, setShowToolTipDes] = useState(false);
  const [formSubmittedSuccessfully, setFormSubmittedSuccessfully] = useState(null);
  const [error, setError] = useState(null);
  const [formExpand, setFormExpand] = useState(true);
  const [isListening, setIsListening] = useState(false);

  const handleGAEventOnSubmit = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'form',
      form: {
        action: 'submit',
        id: 'cdf-react',
        name: 'CDF',
        type: 'Contact detailed form',
        category: 'Contact forms',
        success: true,
      },
    });
  };

  const affiliateMarketingOnSubmit = (dealID) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      TDUniqueID: dealID,
      ordervalue: 1,
    });
  };

  useEffect(() => {
    if (sideBar) {
      setFormExpand(false);
    }
    const test = getTrackingParams();
  }, [setFormExpand, sideBar]);

  const expand = () => {
    setFormExpand(true);
  };

  useEffect(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

    if (SpeechRecognition) {
      recognitionRef.current = new SpeechRecognition();
      recognitionRef.current.lang = 'en-GB';
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = true;

      recognitionRef.current.onstart = () => {
        console.log('Speech recognition started');
        setIsListening(true);
      };

      recognitionRef.current.onend = () => {
        console.log('Speech recognition ended');
        setIsListening(false);
      };
    } else {
      console.error('Speech recognition is not supported in this browser.');
    }
  }, []);

  const handleFormSubmit = async (values) => {
    try {
      if (!isUserLoggedIn) {
        values.tracking = getTrackingParams();
      }
      const formType = 'CDF';
      const contact = true;
      values = { ...values, contact, formType };

      const res = await axios.post(`${process.env.NEXT_PUBLIC_ROTA_URL}create-deal`, values).then(legacyParseFirstJsonInResponse);
      const dealID = res?.data?.id;
      const deal_access_token = res?.data?.deal_access_token;
      handleGAEventOnSubmit();
      affiliateMarketingOnSubmit(dealID);
      setFormSubmittedSuccessfully(true);
      window.location.href = `/contact-us2?dealId=${dealID}&deal_access_token=${deal_access_token}&email=${encodeURIComponent(values.email)}`;
    } catch (error) {
      console.error('Error submitting form:', error);
      setFormSubmittedSuccessfully(false);
      if (error.response?.status === 409) {
        setError('email-already-in-use');
      } else {
        setError(error);
        Sentry.captureException(error, {
          tags: { page: "CDF" },
          extra: {
            description: "Error submitting CDF",
            context: { ...values },
          },
        });
      }
    }
  };

  const onClickYearsEmployed = () => {
    setShowToolTipYe(!showToolTipYe);
    setShowToolTipAi(false);
    setShowToolTipSa(false);
    setShowToolTipDes(false);
  };

  const onClickAnnualIncome = () => {
    setShowToolTipYe(false);
    setShowToolTipAi(!showToolTipAi);
    setShowToolTipSa(false);
    setShowToolTipDes(false);
  };

  const onClickSettlementAgreement = () => {
    setShowToolTipYe(false);
    setShowToolTipAi(false);
    setShowToolTipSa(!showToolTipSa);
    setShowToolTipDes(false);
  };

  const onClickDescription = () => {
    setShowToolTipYe(false);
    setShowToolTipAi(false);
    setShowToolTipSa(false);
    setShowToolTipDes(!showToolTipDes);
  };

  const handleSpeechRecognition = (setFieldValue, currentDescription) => {
    if (!recognitionRef.current) return;

    recognitionRef.current.onresult = (event) => {
      let finalTranscript = '';

      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          finalTranscript += transcript;
        }
      }

      // Safely append the recognized text to the existing description value
      setFieldValue('description', currentDescription + ' ' + finalTranscript.trim());
    };

    if (isListening) {
      recognitionRef.current.stop();
    } else {
      recognitionRef.current.start();
    }
  };

  return (
    <div className={`cdf ${classNames}`} onClick={expand}>
      <Formik
        initialValues={{
          'call-taken-by': '',
          name: '',
          job: '',
          email: '',
          phone: '',
          'years-employed': '',
          'still-employed': '',
          salary: '',
          'settlement-agreement': '',
          'what-is-your-objective': '',
          description: '',
          terms: '',
        }}
        onSubmit={handleFormSubmit}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        {({ errors, touched, isValid, isSubmitting, setFieldValue, values }) => (
          <Form className="cdf__form">
            {isUserLoggedIn && (
              <div className="cdf__form__call-taken-by">
                <p>Call taken by:</p>
                <Field as="select" name="call-taken-by">
                  <option defaultValue="selected">-Select -</option>
                  <option value="Angelo">Angelo</option>
                  <option value="Annie">Annie</option>
                  <option value="Fiammetta">Fiammetta</option>
                  <option value="Haydn">Haydn</option>
                  <option value="Imogen">Imogen</option>
                  <option value="Sana">Sana</option>
                  <option value="Terenia">Terenia</option>
                  <option value="Tolu">Tolu</option>
                </Field>
              </div>
            )}
            <div className="cdf__form__first-step">
              <h5>{sideBar ? 'Contact us today' : 'Your Details'}</h5>
              {sideBar && <p>We'll let you know how we can help within 1 working hour</p>}
              <div className="cdf__first-step__fields">
                <div className="cdf__field__wrapper">
                  <p>
                    Full Name <span>*</span>
                  </p>
                  <Field type="text" name="name" />
                  {errors.name && touched.name ? (
                    <i className="cdf__field__error">{errors.name}</i>
                  ) : null}
                </div>
                {!contactFieldLayout && (
                  <div className="cdf__field__wrapper">
                    <p>Latest job title</p>
                    <Field type="text" name="job" />
                  </div>
                )}
                <div className="cdf__field__wrapper">
                  <p>
                    Email <span>*</span>
                  </p>
                  <Field type="email" name="email" />
                  {errors.email && touched.email ? (
                    <i className="cdf__field__error">{errors.email}</i>
                  ) : null}
                </div>

                <div className="cdf__field__wrapper">
                  <p>Phone</p>
                  <Field type="phone" name="phone" pattern="[0-9]*" inputMode="numeric" />
                </div>
                {contactFieldLayout && (
                  <div className="cdf__field__wrapper text-area-speech">
                    <p>
                      Brief description of case<span>*</span>
                    </p>
                    <Field rows={12} name="description" component="textarea" maxLength="5000" />
                    <button
                      type="button"
                      onClick={() => handleSpeechRecognition(setFieldValue, values.description)} // Pass the current description value
                    >
                      {isListening ? (
                        <img src="/images/microphone.png" width="24" height="24" alt="microphone" />
                      ) : (
                        <img src="/images/mic.png" width="24" height="24" alt="microphone" />
                      )}
                    </button>
                    {errors.description && touched.description ? (
                      <i className="cdf__field__error">{errors.description}</i>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
            {formExpand && (
              <div className="cdf__form__second-step">
                <h5>Your Situation</h5>
                <div className="cdf__second-step__fields">
                  {contactFieldLayout && (
                    <div className="cdf__field__wrapper">
                      <p>Latest job title</p>
                      <Field type="text" name="job" />
                    </div>
                  )}
                  <div className="cdf__second-step__select">
                    <div className="cdf__select-field__wrapper">
                      <div className="cdf__select-field__wrapper__inner">
                        <p>
                          Years employed <span>*</span>
                        </p>
                        <div>
                          <a href="JavaScript:void(0);" onClick={onClickYearsEmployed}>
                            <img
                              src="/images/info-icon.svg"
                              width="20"
                              height="20"
                              alt="info-icon"
                            />
                          </a>
                          {showToolTipYe && (
                            <div className="cdf__tooltip" onClick={() => setShowToolTipYe(false)}>
                              <div className="cdf__tooltip__inner">
                                <p className="cdf__tooltip__inner__title">Years employed</p>
                                <p className="cdf__tooltip__inner__paragraph">
                                  Average years in employment
                                </p>
                                <p className="cdf__tooltip__inner__action">Got it</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <Field as="select" name="years-employed">
                        <option value="" defaultValue="selected">
                          - Select -
                        </option>
                        <option value="Less than 2 years">Less than 2 years</option>
                        <option value="More than 2 years">More than 2 years</option>
                      </Field>
                      {errors['years-employed'] && touched['years-employed'] ? (
                        <i className="cdf__field__error">{errors['years-employed']}</i>
                      ) : null}
                    </div>
                    <div className="cdf__select-field__wrapper">
                      <div className="cdf__select-field__wrapper__inner">
                        <p>
                          Still employed <span>*</span>
                        </p>
                      </div>
                      <Field as="select" name="still-employed">
                        <option value="" defaultValue="selected">
                          - Select -
                        </option>
                        <option value="Yes">Still employed</option>
                        <option value="Resigned">Resigned</option>
                        <option value="Dismissed">Dismissed</option>
                      </Field>
                      {errors['still-employed'] && touched['still-employed'] ? (
                        <i className="cdf__field__error">{errors['still-employed']}</i>
                      ) : null}
                    </div>

                    <div className="cdf__select-field__wrapper">
                      <div className="cdf__select-field__wrapper__inner">
                        <p>
                          Annual income <span>*</span>
                        </p>
                        <div>
                          <a href="JavaScript:void(0);" onClick={onClickAnnualIncome}>
                            <img
                              src="/images/info-icon.svg"
                              width="20"
                              height="20"
                              alt="info-icon"
                            />
                          </a>
                          {showToolTipAi && (
                            <div className="cdf__tooltip" onClick={() => setShowToolTipAi(false)}>
                              <div className="cdf__tooltip__inner">
                                <p className="cdf__tooltip__inner__title">Salary</p>
                                <p className="cdf__tooltip__inner__paragraph">
                                  We use this info to advise you on case value
                                </p>
                                <p className="cdf__tooltip__inner__action">Got it</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <Field as="select" name="salary">
                        <option value="" defaultValue="selected">
                          - Select -
                        </option>
                        <option value="£0 - £30,000">£0 - £30,000</option>
                        <option value="£30,000 - £50,000">£30,000 - £50,000</option>
                        <option value="£50,000 - £100,000">£50,000 - £100,000</option>
                        <option value="£100,000 +">£100,000 +</option>
                      </Field>
                      {errors.salary && touched.salary ? (
                        <i className="cdf__field__error">{errors.salary}</i>
                      ) : null}
                    </div>

                    <div className="cdf__select-field__wrapper">
                      <div className="cdf__select-field__wrapper__inner">
                        <p>
                          Do you have a settlement
                          <br /> agreement document?
                        </p>
                        <div>
                          <a href="JavaScript:void(0);" onClick={onClickSettlementAgreement}>
                            <img
                              src="/images/info-icon.svg"
                              width="20"
                              height="20"
                              alt="info-icon"
                            />
                          </a>
                          {showToolTipSa && (
                            <div className="cdf__tooltip" onClick={() => setShowToolTipSa(false)}>
                              <div className="cdf__tooltip__inner">
                                <p className="cdf__tooltip__inner__title">Settlement agreement</p>
                                <p className="cdf__tooltip__inner__paragraph">
                                  Have you received a settlement offer
                                </p>
                                <p className="cdf__tooltip__inner__action">Got it</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <Field as="select" name="settlement-agreement">
                        <option value="" defaultValue="selected">
                          - Select -
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="Not sure">Not sure</option>
                      </Field>
                    </div>
                    <div className="cdf__select-field__wrapper">
                      <p>Want to leave job?</p>
                      <Field as="select" name="what-is-your-objective">
                        <option value="" defaultValue="selected">
                          - Select -
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="Maybe">Maybe</option>
                        <option value="Already left">Already left</option>
                      </Field>
                    </div>
                  </div>
                  {!contactFieldLayout && (
                    <div className="cdf__form__third-step">
                      <div className="cdf__form__third-step__inner">
                        <p>
                          Brief description of case <span>*</span>
                        </p>
                        <div>
                          <a href="JavaScript:void(0);" onClick={onClickDescription}>
                            <img
                              src="/images/info-icon.svg"
                              width="20"
                              height="20"
                              alt="info-icon"
                            />
                          </a>
                          {showToolTipDes && (
                            <div className="cdf__tooltip" onClick={() => setShowToolTipDes(false)}>
                              <div className="cdf__tooltip__inner">
                                <p className="cdf__tooltip__inner__title">Keep it brief</p>
                                <p className="cdf__tooltip__inner__paragraph">
                                  Outline the key points, less is more
                                </p>
                                <p className="cdf__tooltip__inner__action">Got it</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="text-area-speech">
                        <Field rows={12} name="description" component="textarea" maxLength="5000" />
                        <button
                          type="button"
                          onClick={() => handleSpeechRecognition(setFieldValue, values.description)} // Pass the current description value
                        >
                          {isListening ? (
                            <img
                              src="/images/microphone.png"
                              width="24"
                              height="24"
                              alt="microphone"
                            />
                          ) : (
                            <img src="/images/mic.png" width="24" height="24" alt="microphone" />
                          )}
                        </button>
                      </div>
                      {errors.description && touched.description ? (
                        <i className="cdf__field__error">{errors.description}</i>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>
            )}
            
            <div className="cdf__form__final-step">
              <div className="cdf__field__wrapper">
                <label>
                  <Field type="checkbox" name="terms" value="terms" />
                  <span>
                    I accept the{' '}
                    <a href="/privacy-policy" target="__blank">
                      terms & conditions
                    </a>
                    <span>*</span>
                  </span>
                </label>
                {errors.terms && touched.terms ? (
                  <i className="cdf__field__error">{errors.terms}</i>
                ) : null}
              </div>
            </div>

            <p className="cdf__field__required">
              Required fields are marked <span>*</span>
            </p>

            <button disabled={!isValid || isSubmitting || formSubmittedSuccessfully} className="button" type="submit">
              {isSubmitting ? (
                <div className="submit-loader">
                  <img src="/images/cycle.svg" width="auto" height="auto" alt="cycle" />
                </div>
              ) : (
                'SUBMIT'
              )}
            </button>

            <p className="cdf__field__footer-text">Completely confidential</p>

            {error && (
              error === 'email-already-in-use' ? (
                <p className="cdf__field__footer-text">
                  <strong>Email is already in use</strong>
                </p>
              ) : (<>
                <p className="cdf__field__footer-error">
                  <strong>There was an error when submitting your form</strong>, it is on us not on you.
                </p>
                <p className="cdf__field__footer-error">
                  Please try again later or reach us directly at communications@monacosolicitors.co.uk
                </p>
              </>)
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

CDF.propTypes = {
  contactFieldLayout: PropTypes.bool,
};

export default CDF;
